import React from "react";
import "./Home.css";

function Home() {
  const handleBookNow = () => {
    const contactSection = document.getElementById("contact");
    if (contactSection) {
      contactSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div className="btn-pic-container">
      <div className="btn-container">
        <button onClick={handleBookNow} className="btn-100">
          Boek Direct!
        </button>
      </div>
      <img
        className="home-pic"
        src={process.env.PUBLIC_URL + "/Home_Pic.png"}
        alt="Home background"
      />
    </div>
  );
}

export default Home;
