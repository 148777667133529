import React, { useRef } from "react";
import "../App.css";

function AboutMeCard() {
  const canvasRef = useRef(null);

  return (
    <div className="overflow-hidden px-4">
      <canvas
        ref={canvasRef}
        className="absolute inset-0 pointer-events-none"
      />

      <div className="relative z-auto">
        <h2>Over mij</h2>
        <div>
          <p>
            Mijn naam is Nancy Beerens, geboren in 1995 in Amsterdam. Als klein
            meisje was ik al gek op zingen en karaoke, en tijdens
            familiebijeenkomsten haalde ik altijd de karaoke box tevoorschijn.
            Mijn familie werd mijn grootste fan en moedigde me aan om meer met
            zingen te doen.
          </p>
          <p>
            Op 17-jarige leeftijd deed ik mee aan een talentenjacht, gevolgd
            door meer optredens. Dit leerde me om voor publiek te zingen, wat
            leidde tot optredens door heel Nederland en veel zangervaring.
          </p>
          <p>
            Zingen is mijn passie, vooral muziek uit de jaren 50 tot 80. Mijn
            repertoire is gevarieerd en ik zing nu verschillende genres, zoals
            Pop, Soul, Jazz, Rock en Nederlandstalig. Het meeste plezier haal ik
            uit zingen voor publiek en het blij maken van mensen. Dat is voor
            mij het mooiste gevoel!
          </p>
        </div>
      </div>
    </div>
  );
}

export default AboutMeCard;
