import React, { useState, useEffect, useCallback, useRef } from "react";
import { BrowserRouter as Router, Link, useLocation } from "react-router-dom";
import "./App.css";
import Home from "./components/Home"; // UPDATED
import About from "./components/About";
import MijnAanbod from "./components/MijnAanbod";
import FAQ from "./components/FAQ";
import ContactForm from "./components/contactform";

/** Throttle function for scroll handling */
const throttle = (func, limit) => {
  let inThrottle;
  return function () {
    const args = arguments;
    const context = this;
    if (!inThrottle) {
      func.apply(context, args);
      inThrottle = true;
      setTimeout(() => (inThrottle = false), limit);
    }
  };
};

/** NavLink Component */
const NavLink = ({ to, children, onClick }) => {
  const location = useLocation();
  const isActive = location.pathname === to;

  const handleClick = (e) => {
    e.preventDefault();
    const element = document.getElementById(to.slice(1));
    if (element) {
      const headerOffset =
        document.querySelector(".sticky-header").offsetHeight;
      const elementPosition = element.getBoundingClientRect().top;
      const offsetPosition =
        elementPosition + window.pageYOffset - headerOffset;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
    if (onClick) onClick();
  };

  return (
    <Link
      to={to}
      className={`nav-link ${isActive ? "active" : ""}`}
      onClick={handleClick}
    >
      {children}
    </Link>
  );
};

function App() {
  const [isNavExpanded, setIsNavExpanded] = useState(false);
  const navRef = useRef(null);

  const toggleNav = () => {
    setIsNavExpanded((prev) => !prev);
  };

  const closeNav = useCallback(() => {
    setIsNavExpanded(false);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (navRef.current && !navRef.current.contains(event.target)) {
        setIsNavExpanded(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  /** Handle scroll to update URL based on current section */
  const handleScroll = useCallback(() => {
    const throttledScroll = throttle(() => {
      const sections = ["home", "about", "mijn-aanbod", "faq", "contact"];
      let currentSection = "";

      sections.forEach((section) => {
        const element = document.getElementById(section);
        if (element && window.scrollY >= element.offsetTop - 200) {
          currentSection = section;
        }
      });

      if (currentSection) {
        window.history.pushState(
          null,
          "",
          `/${currentSection === "home" ? "" : currentSection}`
        );
      }
    }, 200);

    throttledScroll();
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [handleScroll]);

  /* Inline styling for the background <div> */
  const backgroundStyle = {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    zIndex: -1,
    backgroundImage: `url(${process.env.PUBLIC_URL}/Background.png)`,
    backgroundRepeat: "repeat",
    backgroundPosition: "center center",
    backgroundSize: "cover",
  };

  return (
    <Router>
      <div className="App" style={{ position: "relative" }}>
        {/* Inline Background <div> */}
        <div style={backgroundStyle}></div>

        {/* Sticky Header & Banner */}
        <div className="sticky-header">
          <div className="banner-container">
            {/* Banner background image from public/ */}
            <img
              src={process.env.PUBLIC_URL + "/Banner_BG.png"}
              alt="Background"
              className="banner-bg"
              width="1920"
              height="300"
              loading="eager"
              fetchpriority="high"
            />
            {/* Banner foreground image from public/ */}
            <img
              src={process.env.PUBLIC_URL + "/Banner_content.png"}
              alt="NB Entertainment"
              className="banner-content"
              width="1920"
              height="300"
              loading="eager"
              fetchpriority="high"
            />
          </div>

          <nav
            ref={navRef}
            className={`custom-navbar ${isNavExpanded ? "expanded" : ""}`}
          >
            <button
              className="navbar-toggle"
              onClick={toggleNav}
              title="Open menu"
            >
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
              <span className="toggle-text">Menu</span>
            </button>
            <ul className="nav-menu">
              <li>
                <NavLink to="/" onClick={closeNav}>
                  Home
                </NavLink>
              </li>
              <li>
                <NavLink to="/about" onClick={closeNav}>
                  About
                </NavLink>
              </li>
              <li>
                <NavLink to="/mijn-aanbod" onClick={closeNav}>
                  Mijn Aanbod
                </NavLink>
              </li>
              <li>
                <NavLink to="/faq" onClick={closeNav}>
                  FAQ
                </NavLink>
              </li>
              <li>
                <NavLink to="/contact" onClick={closeNav}>
                  Contact
                </NavLink>
              </li>
            </ul>
          </nav>
        </div>

        {/* Main Content */}
        <main className="content">
          {/* HOME SECTION */}
          <section id="home">
            <Home />
          </section>

          {/* ABOUT SECTION */}
          <section id="about">
            <About />
          </section>

          {/* MIJN AANBOD */}
          <section id="mijn-aanbod">
            <MijnAanbod />
          </section>

          {/* FAQ */}
          <section id="faq">
            <FAQ />
          </section>

          {/* CONTACT */}
          <section id="contact">
            <ContactForm />
          </section>
        </main>
      </div>
    </Router>
  );
}

export default App;
