import React, { useState, useEffect, useRef } from "react";
import "./MijnAanbod.css";

const LazySpotifyEmbed = ({ src, title }) => {
  const [isVisible, setIsVisible] = useState(false);
  const embedRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.disconnect();
        }
      },
      { threshold: 0.1 }
    );

    if (embedRef.current) {
      observer.observe(embedRef.current);
    }

    return () => observer.disconnect();
  }, []);

  return (
    <div ref={embedRef} className="spotify-embed-container">
      {isVisible ? (
        <iframe
          src={src}
          width="100%"
          height="352"
          frameBorder="0"
          allowFullScreen=""
          allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
          loading="lazy"
          className="rounded-lg"
          title={title}
        ></iframe>
      ) : (
        <div className="spotify-placeholder" style={{ height: "352px" }}>
          <p>Loading {title}...</p>
        </div>
      )}
    </div>
  );
};

function MijnAanbod() {
  return (
    <div>
      <h1 className="text-3xl font-bold mb-4">Mijn aanbod</h1>
      <div className="list-disc list-inside">
        <p>
          Onderstaande playlists zijn bedoeld als impressie maar zijn geen
          opnamens van Nancy zelf
        </p>
        <div className="mt-8 px-4">
          <div className="playlist-grid">
            <div className="playlist-card">
              <h2 className="text-2xl font-bold">Diner Playlist</h2>
              <p>Deze Playlist is bedoeld als achtergrondmuziek</p>
              <LazySpotifyEmbed
                src="https://open.spotify.com/embed/playlist/177lkSpAJCBOpKV8xtZYaU?utm_source=generator&theme=0"
                title="Diner Playlist"
              />
            </div>
            <div className="playlist-card">
              <h2 className="text-2xl font-bold">Feest Playlist</h2>
              <p>Deze Playlist is bedoeld om in de feeststemming te komen</p>
              <LazySpotifyEmbed
                src="https://open.spotify.com/embed/playlist/7znWUzAQvSjfQoxkkhj5pi?utm_source=generator&theme=0"
                title="Feest Playlist"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MijnAanbod;
