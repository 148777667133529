import React, { useState, useRef, useEffect } from "react";

function ContactForm() {
  const [selectedOption, setSelectedOption] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const options = [
    "Ik zou graag een vraag willen stellen",
    "Boekingsaanvraag doen",
  ];

  const handleSelect = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  return (
    <div className="w-full max-w-[95%] md:max-w-[80%] lg:max-w-[60%] xl:max-w-[50%] mx-auto px-4 sm:px-6 lg:px-8" id="contact">
      <div className="backdrop-blur-md bg-[#32312F]/70 shadow-lg rounded-lg overflow-hidden p-4 sm:p-6 lg:p-8 transform transition-all hover:shadow-xl border border-white/10">
        <h2 className="text-[#FFFFFF]/90 text-lg sm:text-xl font-semibold mb-4 leading-relaxed">
          Via onderstaand formulier kunt u een Boekingsaanvraag doen
          <p>Aanvragen worden binnen c.a. 24uur beantwoord</p>
        </h2>
        <form
          action="https://formspree.io/f/xldrkwje"
          method="POST"
          className="space-y-4"
        >
          {/* Dropdown Menu */}
          <div className="relative" ref={dropdownRef}>
            <label
              htmlFor="optie"
              className="block text-[#FFFFFF]/90 text-sm font-medium mb-2"
            >
              Selecteer een optie:
            </label>
            <input
              type="text"
              id="optie"
              name="optie"
              value={selectedOption}
              onChange={(e) => setSelectedOption(e.target.value)}
              onClick={() => setIsOpen(true)}
              placeholder="Kies een optie"
              className="w-full px-3 sm:px-4 py-2 sm:py-3 border-2 border-[#D5CEAF]/50 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#32312F] focus:border-transparent text-[#FFFFFF] bg-[#32312F]/50 cursor-pointer transition-all duration-300 backdrop-blur-sm text-sm sm:text-base"
            />
            {isOpen && (
              <ul className="absolute z-10 w-full mt-1 bg-[#32312F]/80 backdrop-blur-md border-2 border-[#D5CEAF]/30 rounded-lg shadow-lg max-h-60 overflow-auto">
                {options.map((option) => (
                  <li
                    key={option}
                    className="px-3 py-2 hover:bg-[#32312F]/90 cursor-pointer text-[#FFFFFF] transition-all duration-300"
                    onClick={() => handleSelect(option)}
                  >
                    {option}
                  </li>
                ))}
              </ul>
            )}
          </div>

          {/* Name Input */}
          <div>
            <label
              htmlFor="name"
              className="block text-[#FFFFFF]/90 text-sm font-medium mb-2"
            >
              Naam:
            </label>
            <input
              type="text"
              id="name"
              name="name"
              required
              className="w-full px-3 sm:px-4 py-2 sm:py-3 border-2 border-[#D5CEAF]/30 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#32312F] focus:border-transparent text-[#FFFFFF] bg-[#32312F]/50 backdrop-blur-sm transition-all duration-300 placeholder-white/50 text-sm sm:text-base"
            />
          </div>

          {/* Email Input */}
          <div>
            <label
              htmlFor="email"
              className="block text-[#FFFFFF]/90 text-sm font-medium mb-2"
            >
              E-mail:
            </label>
            <input
              type="email"
              id="email"
              name="email"
              required
              className="w-full px-3 sm:px-4 py-2 sm:py-3 border-2 border-[#D5CEAF]/30 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#32312F] focus:border-transparent text-[#FFFFFF] bg-[#32312F]/50 backdrop-blur-sm transition-all duration-300 placeholder-white/50 text-sm sm:text-base"
            />
          </div>

          {/* Message Input */}
          <div>
            <label
              htmlFor="message"
              className="block text-[#FFFFFF]/90 text-sm font-medium mb-2"
            >
              Bericht:
            </label>
            <textarea
              id="message"
              name="message"
              required
              rows="5"
              className="w-full px-3 sm:px-4 py-2 sm:py-3 border-2 border-[#D5CEAF]/30 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#32312F] focus:border-transparent text-[#FFFFFF] bg-[#32312F]/50 backdrop-blur-sm transition-all duration-300 resize-none placeholder-white/50 text-sm sm:text-base"
            ></textarea>
          </div>

          {/* Submit Button */}
          <button
            type="submit"
            className="w-full bg-[#32312F] text-[#FFFFFF] py-2 sm:py-3 px-4 sm:px-6 rounded-lg hover:bg-[#32312F]/80 transition-all duration-300 transform hover:scale-[1.02] font-medium text-base sm:text-lg mt-4 shadow-lg backdrop-blur-sm border border-white/10"
          >
            Send
          </button>
        </form>
      </div>
    </div>
  );
}

export default ContactForm;
